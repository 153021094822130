
import dayjs, { Dayjs } from "dayjs";
import { defineComponent } from "vue";
import EventDateTip from "./EventDateTip.vue";
import store from "@/store/store";

function timeIsGood(voteTime: Dayjs) {
  const rightNow = dayjs();
  const eventStart = dayjs(store.state.eventWizardContext.eventStartDateTime);
  return voteTime.isAfter(rightNow) && voteTime.isBefore(eventStart);
}

export default defineComponent({
  components: {
    EventDateTip,
  },
  mounted() {
    const esdt: Dayjs = this.dayjs(
      this.$store.state.eventWizardContext.eventStartDateTime
    );
    const minutesToSub = esdt.diff(this.dayjs(), "minute") / 2;
    const safeTime = esdt.subtract(minutesToSub, "minute");
    // round to nearest half hour if it is still before the event time and after right now
    let rvet = safeTime;

    const minutes = rvet.minute();
    if (minutes < 15) {
      rvet = rvet.minute(0);
    } else if (minutes < 45) {
      rvet = rvet.minute(30);
    } else {
      rvet = rvet.minute(0);
      rvet = rvet.add(1, "hour");
    }

    if (timeIsGood(rvet)) {
      this.restaurantVoteEndDateTime = rvet;
    } else {
      this.restaurantVoteEndDateTime = safeTime;
    }
    if (this.allFieldsFilled) {
      this.$emit("enable-next");
    }
  },
  data() {
    const maxTime = {
      hours: dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      ).hour(),
      minutes: dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      ).minute(),
    };
    const highlighted = this.generateHighlightedDates();
    const rightNow = Date.now();
    const A_DAY_IN_MS = 86400000;
    const MS_TO_HR_DIVISION_FACTOR = 3600000;
    return {
      maxTime,
      highlighted,
      A_DAY_IN_MS,
      rightNow,
      MS_TO_HR_DIVISION_FACTOR,
      endDate: "",
      endTime: "",
    };
  },
  watch: {
    allFieldsFilled: {
      handler(n, o) {
        if (n) {
          this.$emit("enable-next");
        } else {
          this.$emit("disable-next");
        }
      },
    },
  },
  methods: {
    generateHighlightedDates() {
      const highlightedDates = [];
      const eventStartDateTime = dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      );
      const daysDifference = eventStartDateTime.diff(dayjs(), "day");

      for (let i = 0; i <= daysDifference; i++) {
        highlightedDates.push(dayjs().add(i, "day").toDate());
      }
      return highlightedDates;
    },
    dayjs,
  },
  computed: {
    beforeEventStarts() {
      const hours = this.dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      ).diff(this.dayjs(this.restaurantVoteEndDateTime), "hour");
      if (hours > 1) {
        return hours + " hours";
      } else {
        return (
          this.dayjs(
            this.$store.state.eventWizardContext.eventStartDateTime
          ).diff(this.dayjs(this.restaurantVoteEndDateTime), "minute") +
          " minutes"
        );
      }
    },
    formattedVoteEnd() {
      return this.dayjs(this.restaurantVoteEndDateTime).fromNow();
    },
    formattedEventStartDateTime() {
      return this.dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      ).fromNow();
    },
    allFieldsFilled() {
      return (
        this.voteEndDateTimeBeforeEventStarts &&
        this.voteEndInFuture &&
        !!this.sessionSelected
      );
    },
    restaurantVoteEndDateTime: {
      get() {
        return this.$store.state.eventWizardContext.restaurantVoteEndDateTime;
      },
      set(v) {
        this.$store.commit("setEventWizardRestaurantVoteEndDateTimeContext", v);
      },
    },
    sessionSelected: {
      get() {
        return this.$store.state.eventWizardContext.sessionType;
      },
      set(st) {
        this.$store.commit("setEventWizardSessionTypeContext", st);
      },
    },
    beforeEventStartsString() {
      return (
        "Voting ending " +
        this.$store.state.eventWizardContext.eventStartDateTime.toDateString() +
        " at " +
        this.$store.state.eventWizardContext.eventStartDateTime.toTimeString() +
        ")"
      );
    },
    voteEndInFuture(): boolean {
      return this.dayjs(this.restaurantVoteEndDateTime).isAfter(this.dayjs());
    },
    voteEndDateTimeBeforeEventStarts(): boolean {
      const result = this.dayjs(
        this.$store.state.eventWizardContext.eventStartDateTime
      ).isAfter(this.dayjs(this.restaurantVoteEndDateTime));
      return result;
    },
  },
});
